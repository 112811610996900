import { Component } from "react"
import anim from './Cube.module.scss';

class Cube extends Component {
	render() {


		return (
			<div className={anim.Cubes}>

				<div className={anim.container}>
					<div className={anim.monitor}>
						<div className={anim.camera}>
							<div className={anim.camera}>
								<div className={anim.camera}>
									<div className={anim.cubes}>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
										<div className={anim.cube}>
											<div className={anim.scale}>
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
												<div className={anim.panel} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);


	}
}
export default Cube