import { Component } from "react";
import HomeImage from "../components/pagesComponents/home/homeImage/HomeImage"

import "../scss/Anim.module.scss"
class Home extends Component {
  

    render() {
        return (          
              <HomeImage></HomeImage>
              );
  }
}
export default Home;
